<template>
  <div class="h-100">
    <v-row class="h-100" justify="center" align="center" no-gutters >
      <v-col xl="8" lg="10" md="12">
        <v-card>
          <v-card-item>
              <!--<v-card-title><h1 class="text-h4">Насосы «Гранпамп»</h1></v-card-title>-->
              <div class="flex_title">
                  <h1 class="text-h4">Насосы «Гранпамп»</h1>
                  <v-btn @click="$router.push('/')" color="var(--root-color)" variant="tonal">назад</v-btn>
              </div>
              <v-card-subtitle>Заполнение параметров</v-card-subtitle>
            </v-card-item>
            <v-card-text>
              <v-row >
                <v-col class="v-col-12" sm="4" md="4" lg="4" xl="3">
                  <v-text-field v-model="pump_name" label="Наименование" variant="outlined" density="compact"></v-text-field>
                  <v-text-field v-model="pump_art" label="Артикул" variant="outlined" density="compact"></v-text-field>
                  <v-tooltip open-delay="0" max-width="360px" location="top" text="Программа подбирает насосное оборудование для воды. Для заказа насосного оборудования на другие среды, просьба направить заявку на почту info@adl.ru">
                    <template v-slot:activator="{ props }">
                      <v-text-field class="disabled_input" v-bind="props" v-model="fluid_type" label="Тип жидкости" variant="outlined" density="compact" readonly :append-inner-icon="'mdi-help-circle-outline'"></v-text-field>
                    </template>
                  </v-tooltip>
                  <v-select v-model="pump_type" clearable label="Тип насоса" :items="pump_types_filtered" variant="outlined" density="compact"></v-select>
                  <v-select v-model="pump_vid" clearable label="Вид насосов" :items="types_filtered" variant="outlined" density="compact"></v-select>
                  <v-select v-model="system_type_f" clearable label="Тип системы" :items="system_type" variant="outlined" density="compact"></v-select>
                  <h3>Рабочая точка</h3><br>
                  <v-row >
                    <v-col sm="6">
                      <v-text-field min="0.01" v-model="work_q" label="Q (м3/ч)" type="number" variant="outlined" density="compact"></v-text-field>
                    </v-col>
                    <v-col sm="6">
                      <v-text-field min="0.01" v-model="work_h" label="H (м)" type="number" variant="outlined" density="compact"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-switch v-model="work_point_off" color="var(--root-color)" label="Не учитывать отбор по рабочей точке"></v-switch>
                  <v-btn :disabled="buttonDisabled()" :loading="loading" @click="updatePumpsList()" variant="tonal" block color="var(--root-color)">Подобрать</v-btn>
                </v-col>
                <v-col class="v-col-12" xs="8" sm="8" md="6" lg="6" xl="7" >
                  <v-list v-if="items.length > 0" class="pump_list">
                    <transition-group name="list">
                      <v-list-item @click="openDialog(item)" :value="item" color="var(--root-color)" variant="tonal" :style="{ transitionDelay: item.i+'s' }" v-for="item in items" :key="item.ID" class="list-item">
                        <v-list-item-title v-text="item.UF_MODEL"></v-list-item-title>
                      </v-list-item>
                    </transition-group>
                  </v-list>
                  <div v-else>
                    <p v-if="empty_str == 'Y'">
                      <v-alert class="not_found_alert" title="" type="info">
                        По Вашему запросу не найдено подходящих насосов. Заполните <a href="https://adl.ru/upload/adl-doc/NO_podbor_nasosa.doc">Опросный лист</a> и отправьте на <a href="mailto:info@adl.ru">info@adl.ru</a> 
                      </v-alert>
                    </p>
                    <p v-else>{{ empty_str }}</p>
                  </div>
                </v-col>
                <v-col class="v-col-12" xs="12" sm="12" md="2" xl="2" lg="2">
                  <img class="select_img" :src="require('@/assets/1.png')" alt="" />
                  <img class="select_img" :src="require('@/assets/2.png')" alt="" />
                  <img class="select_img" :src="require('@/assets/3.png')" alt="" />
                </v-col>
              </v-row>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen>
      <v-card>
        <v-toolbar class="pump_toolbar" color="var(--root-light)">
          <v-btn
            icon="mdi-close"
            @click="dialog = false"
          ></v-btn>

          <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>

        </v-toolbar>
        
        <GranPumpDetail :pump="active_pump" :work_point="work_point" :user_query="last_params"/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
import GranPumpDetail from '@/components/GranPumpDetail.vue'
import axios from "axios";



export default defineComponent({
  components: {
    GranPumpDetail
  },
  data () {
    return {
      dialog: false,
      dialogTitle: 'Насос',
      pump_types: ['МНС','ВМН','ЦНВ'],
      types: ['Вертикальный','Горизонтальный'],
      pump_types_filtered: new Array(0),
      types_filtered: new Array(0),
      system_type: ['ХВС','ГВС', 'Отопление', 'Кондиционирование', 'Пожаротушение', 'Циркуляция'],
      max_temp: '',//температура
      max_pressure: '',//дпаление
      work_q: '',
      work_h: '',
      items: new Array(0),
      delay: 0,
      list_items_delay_s: 0.2,
      empty_str: "Заполните необходимые поля и нажмите \"Подобрать насос\"",
      active_pump: new Object(),
      work_point: {x:-1,y:-1},
      loading: false,
      //переменные на отправку
      work_point_off: false,
      pump_name: '',
      pump_art: '',
      pump_type: '',
      pump_vid: '',
      system_type_f: '',
      fluid_type: 'Чистая вода',
      last_params: {
        Q: 0,
        H: 0,
        name: '',
        art: '',
        pumpType: '',
        vid: '',
        systemType: '',
        fluid_type: 'Чистая вода'
      }
    }
  },
  methods:{
    async findPumps(){
      //this.items = [];
      /*let api_list = [
        {
          name: 'Насос горизонтальный Гранпамп МНС 150',
          id: 1,
        },
        {
          name: 'Насос вертикальныЙ Гранпамп ВМН 32',
          id: 2,
        },
        {
          name: 'Насос циркуляционнй Гранпамп ЦНВ 300',
          id: 3,
        },
      ];
      api_list.forEach((element, index) => {
        let tmp_element = element;
        Object.assign(tmp_element, {i: index*this.list_items_delay_s});
        this.items.push(tmp_element)
      })*/

      this.work_point.y = parseFloat(this.work_h as any)
      this.work_point.x = parseFloat(this.work_q as any)
      this.loading = true;
      this.empty_str = "Поиск..."
      try{
        const data = await axios.get('/api/', {
          params: {
            type: "Pumps",
            cmd: "Search",
            Q: this.work_q,
            H: this.work_h,
            pointOff: this.work_point_off,
            name: this.pump_name,
            art: this.pump_art,
            pumpType: this.pump_type,
            vid: this.pump_vid,
            systemType: this.system_type_f
          }
        });
        if(data.data && data.data.data && data.data.data.length > 0){
          data.data.data.forEach((element:any, index:any) => {
            let tmp_element = element;
            Object.assign(tmp_element, {i: index*this.list_items_delay_s});
            this.items.push(tmp_element)
          })
        } else {
          this.empty_str = "Y"
        }

        this.last_params = {
          Q: parseFloat(this.work_q),
          H: parseFloat(this.work_h),
          name: this.pump_name,
          art: this.pump_art,
          pumpType: this.pump_type,
          vid: this.pump_vid,
          systemType: this.system_type_f,
          fluid_type: this.fluid_type
        }
      } catch(e: any){
        console.log(e)
      }
      this.loading = false;
    },
    updatePumpsList(){
      this.items.forEach((element, index) => {
        element.i = ((this.items.length-1)-index)*this.list_items_delay_s;
      })
      /*if(this.items.length > 0){
        setTimeout(() => {
          this.items = [];       
        }, 50);
      }
      setTimeout(() => {
        this.findPumps();
      }, this.items.length*this.list_items_delay_s*1000)*/
      this.items = [];
      this.findPumps();

    },
    openDialog(pump: any){
      this.dialogTitle = pump.UF_MODEL+' (арт. '+pump.UF_CODE+')';
      this.active_pump = pump;
      this.dialog = true;
    },
    buttonDisabled(){
      if(
        ((isNaN(parseFloat(this.work_q)) || isNaN(parseFloat(this.work_h)) || parseFloat(this.work_q) <= 0 || parseFloat(this.work_h) <= 0) || this.work_point_off)
        &&
        (
          (!this.pump_name || this.pump_name.length == 0) &&
          (!this.pump_art || this.pump_art.length == 0) &&
          (!this.pump_type || this.pump_type.length == 0) &&
          (!this.pump_vid || this.pump_vid.length == 0) &&
          (!this.system_type_f || this.system_type_f.length == 0)
          
        ) 
      ){
        return true;
      }
      return false;
    },

    filterTypes(){
      if(this.pump_type == 'МНС'){
        this.types_filtered = ['Горизонтальный']
      }
      if(this.pump_type == 'ВМН' || this.pump_type == 'ЦНВ'){
        this.types_filtered = ['Вертикальный']
      }

      if(!this.pump_type || this.pump_type.length == 0){
        this.types_filtered = this.types;
      }


      if(this.pump_vid == 'Горизонтальный'){
        this.pump_types_filtered = ['МНС']
      }
      if(this.pump_vid == 'Вертикальный'){
        this.pump_types_filtered = ['ВМН','ЦНВ']
      }
      if(!this.pump_vid || this.pump_vid.length == 0){
        this.pump_types_filtered = this.pump_types;
      }
    }

  },
  mounted() {
    this.filterTypes()
  },
  watch:{
    pump_type(){
      this.filterTypes()
    },
    pump_vid(){
      this.filterTypes()
    }
  }
})


</script>
<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.pump_toolbar{
  color: var(--root-color);
}
.pump_list{
  max-height: calc(100vh - 230px);
}

.flex_title {
  display: flex;
  justify-content: space-between;
}
.flex_title h1.text-h4{
  max-width: 100%;
  white-space: normal;
}

@media(max-width:768px){
  .flex_title {
    flex-wrap: wrap;
  }
  .flex_title h1.text-h4{
    order: +1;
  }
}
</style>
<style>
.disabled_input .v-field__field {
  pointer-events: none;
  opacity: 0.6;
}
.disabled_input {
  pointer-events: none;
}
.disabled_input .v-field__append-inner {
  pointer-events: all;
}
.select_img{
  max-width: 100%;
  max-height: 200px;
  display: block;
  margin: 0px auto;
}
</style>